import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Footer from '../../Components/Layout/Footer';
import { Images } from '../../Themes';

import { ValueCard, LeadCard, TestimonialCard } from '../../Components/Block';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import Seo from '../../Routing/Seo';

type Props = {
  classes: Object,
  history: Object,
};

const styles = theme => ({
  container: {
    backgroundColor: 'white',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,

    margin: '2rem 0',
  },
  TestimonialLayout: {
    backgroundColor: '#f1f1f1',
    padding: '2rem 0 2rem 0',
    marginTop: '2rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    height: '100%',
    backgroundImage: `url(${Images})`,
    backgroundSize: 'cover',
    backgroundColor: theme.scalaa.primary.thin,
    padding: '9rem 0',
  },
  heroSecondaryTitle: {
    color: theme.scalaa.primary.main,
    fontWeight: '800',
  },
  heroButton: {
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  media: {
    height: 0,
    paddingTop: '50%',
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  formControl: {
    borderRadius: theme.shape.borderRadius,
  },
  activityLayout: {
    width: '100%',
    margin: '3rem 5rem 4rem',
  },
  valueCard: {
    width: '80px',
    height: '80px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  submitButton: {
    height: '55px',
  },
});

function ProPage({ classes, history }: Props) {
  const { t } = useTranslation();

  const [service, setService] = React.useState('');
  const [serviceError, setServiceError] = React.useState(null);

  const handleSubmit = event => {
    setService(event.target.value);

    if (service === 'lille') {
      history.push(`/signup/on/la-scalaa/scalaa/volunteer`);
    }
    if (service === 'arras') {
      history.push(`/signup/on/maison-des-aidants-de-l-arrageois/la-scalaa-arras/volunteer`);
    }
    if (service === 'bethune') {
      history.push(`/signup/on/le-relais-des-aidants/bethune/volunteer`);
    }
    if (service === 'douai') {
      history.push(
        `/signup/on/pfr-du-douaisis/plateforme-d-accompagnement-et-de-repit-des-aidants-du-douaisis/volunteer`,
      );
    }
    if (service === 'cambrai') {
      history.push(
        `/signup/on/maison-des-aidants-du-cambresis/maison-des-aidants-du-cambresis/volunteer`,
      );
    }
    if (service === 'carvin') {
      history.push(`/signup/on/maison-des-aidants-d-henin-carvin/la-scalaa-carvin/volunteer`);
    }
    if (service === 'lequesnoy') {
      history.push(`/signup/on/pfr-du-valenciennois-quercitain/le-quesnoy/volunteer`);
    }
    if (service === 'lens') {
      history.push(`/signup/on/pfr-le-temps-d-une-pause/la-scalaa-lens/volunteer`);
    }
    if (service === 'amiens') {
      history.push(`/signup/on/pfr-aliis/la-scalaa-amiens/volunteer`);
    }
  };

  return (
    <>
      <Helmet>
        {Seo.title('Devenir bénévole – La SCALAA métropole Lille')}
        {Seo.description(
          'Aidez les aidants familiaux près de chez vous à titre bénévoles, de manière occasionnelle ou régulière',
        )}
      </Helmet>
      <Grid container className={classes.container}>
        {/* HERO CTA SECTION */}
        <Grid item className={classes.heroLayout}>
          <Grid item align="center" className={classNames(classes.layout, classes.heroContent)}>
            <Typography variant="h1" align="center" gutterBottom>
              {t('SCALAA.PRO.TITLE')}
            </Typography>
            <Typography
              variant="h4"
              align="center"
              className={classes.heroSecondaryTitle}
              paragraph
            >
              {t('SCALAA.PRO.SUBTITLE')}
            </Typography>
            <Typography variant="subtitle1" align="center" paragraph>
              {t('SCALAA.PRO.SUBTITLE.BODY')}
            </Typography>

            <Grid container justify="center" spacing={2}>
              <Grid item md={6}>
                <Select
                  align="left"
                  fullWidth
                  displayEmpty
                  value={service}
                  className={classes.formControl}
                  onChange={e => {
                    setService(e.target.value);
                    setServiceError(null);
                  }}
                  input={<OutlinedInput labelWidth={0} name="activity" id="activity-simple" />}
                  variant="outlined"
                  error={serviceError !== null}
                >
                  <MenuItem value="" disabled>
                    <em>Sélectionner la structure la plus proche de chez vous</em>
                  </MenuItem>
                  <MenuItem value="arras">Arras</MenuItem>
                  <MenuItem value="bethune">Bethune</MenuItem>
                  <MenuItem value="cambrai">Cambrai</MenuItem>
                  <MenuItem value="carvin">Carvin</MenuItem>
                  <MenuItem value="douai">Douai</MenuItem>
                  <MenuItem value="lens">Lens Lievin</MenuItem>
                  <MenuItem value="lequesnoy">Le Quesnoy</MenuItem>
                  <MenuItem value="lille">Métropole Lille</MenuItem>
                  <MenuItem value="amiens">Somme Est</MenuItem>
                </Select>
              </Grid>
              <Grid item md={3}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                >
                  Devenir bénévole
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* COVID SECTION */}
        <Grid container>
          <Grid item md={12}>
            <Card style={{ backgroundColor: 'transparent' }} elevation={0}>
              <CardMedia className={classes.media} image={Images.scalaa.covid} />
            </Card>
          </Grid>
        </Grid>

        {/* 3 BENEFIT SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Typography variant="h2" align="center" gutterBottom>
              {t('SCALAA.PRO.BENEFIT.TITLE')}
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom>
              {t('SCALAA.PRO.BENEFIT.SUBTITLE')}
            </Typography>
          </Grid>

          <Grid item md={4} xs={12}>
            <ValueCard
              img={Images.scalaa.value_1}
              title="SCALAA.PRO.BENEFIT.VALUE_1.TITLE"
              content="SCALAA.PRO.BENEFIT.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ValueCard
              img={Images.scalaa.value_2}
              title="SCALAA.PRO.BENEFIT.VALUE_2.TITLE"
              content="SCALAA.PRO.BENEFIT.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <ValueCard
              img={Images.scalaa.value_3}
              title="SCALAA.PRO.BENEFIT.VALUE_3.TITLE"
              content="SCALAA.PRO.BENEFIT.VALUE_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* PRO TESTIMONIAL SECTION */}
        <Grid container justify="center" spacing={4} className={classes.TestimonialLayout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('SCALAA.PRO.DISCOVER.TITLE')}
              </Typography>
              <Typography variant="subtitle1" align="center" gutterBottom>
                {t('SCALAA.PRO.DISCOVER.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
            <TestimonialCard
              avatar={Images.scalaa.avatar_pro_1}
              title={t('SCALAA.PRO.DISCOVER.VALUE_1.TITLE')}
              location={t('SCALAA.PRO.DISCOVER.VALUE_1.LOCATION')}
              content="SCALAA.PRO.DISCOVER.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TestimonialCard
              avatar={Images.scalaa.avatar_pro_2}
              title={t('SCALAA.PRO.DISCOVER.VALUE_2.TITLE')}
              location={t('SCALAA.PRO.DISCOVER.VALUE_2.LOCATION')}
              content="SCALAA.PRO.DISCOVER.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TestimonialCard
              avatar={Images.scalaa.avatar_pro_3}
              title={t('SCALAA.PRO.DISCOVER.VALUE_3.TITLE')}
              location={t('SCALAA.PRO.DISCOVER.VALUE_3.LOCATION')}
              content="SCALAA.PRO.DISCOVER.VALUE_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* PRO JOB SECTION */}
        <Grid container className={classes.activityLayout}>
          <Grid spacing={4} container justify="flex-start">
            <Grid item className={classes.headingContainer}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" gutterBottom>
                  {t('SCALAA.PRO.LEAD.TITLE')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" align="center" gutterBottom>
                  {t('SCALAA.PRO.LEAD.SUBTITLE')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_1}
                title={t('SCALAA.PRO.LEAD.VALUE_1.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_1.LOCATION')}
                leadType={{ slug: 'aide-au-numerique' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_1.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_1.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_1.CONTENT"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_2}
                title={t('SCALAA.PRO.LEAD.VALUE_2.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_2.LOCATION')}
                leadType={{ slug: 'se-promener-a-l-exterieur' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_2.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_2.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_2.CONTENT"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_3}
                title={t('SCALAA.PRO.LEAD.VALUE_3.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_3.LOCATION')}
                leadType={{ slug: 'aide-au-numerique' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_3.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_3.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_3.CONTENT"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_4}
                title={t('SCALAA.PRO.LEAD.VALUE_4.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_4.LOCATION')}
                leadType={{ slug: 'aide-au-numerique' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_4.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_4.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_4.CONTENT"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_5}
                title={t('SCALAA.PRO.LEAD.VALUE_5.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_5.LOCATION')}
                leadType={{ slug: 'aide-au-numerique' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_5.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_5.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_5.CONTENT"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <LeadCard
                avatar={Images.scalaa.avatar_customer_6}
                title={t('SCALAA.PRO.LEAD.VALUE_6.TITLE')}
                location={t('SCALAA.PRO.LEAD.VALUE_6.LOCATION')}
                leadType={{ slug: 'aide-au-numerique' }}
                leadContent={t('SCALAA.PRO.LEAD.VALUE_6.LEADCONTENT')}
                contentTitle="SCALAA.PRO.LEAD.VALUE_6.CONTENT_TITLE"
                content="SCALAA.PRO.LEAD.VALUE_6.CONTENT"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}

export default withStyles(styles)(ProPage);
