import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Helmet } from 'react-helmet';
import Footer from '../../Components/Layout/Footer';
import { Images } from '../../Themes';

import { BulletPointContainer, ValueCard, PortraitCard } from '../../Components/Block';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';

import Seo from '../../Routing/Seo';

type Props = {
  classes: Object,
};

const styles = theme => ({
  container: {
    backgroundColor: 'white',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,

    margin: '2rem 0',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    height: '100%',
    backgroundImage: `url(${Images})`,
    backgroundSize: 'cover',
    backgroundColor: theme.una.primary.thin,
    padding: '10rem 0',
  },
  heroSecondaryTitle: {
    color: theme.una.primary.main,
    fontWeight: '800',
  },
  heroButton: {
    borderColor: theme.palette.common.black,
    color: theme.palette.common.black,
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  proAsideBulletPoints: {
    width: '90%',
    height: '100%',
    backgroundImage: `url(${Images.una.job})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  valueCard: {
    width: '80px',
    height: '80px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
});

function Pro({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        {Seo.title(t(`UNA.SEO.PRO.TITLE`))}
        {Seo.description(t(`UNA.SEO.PRO.DESCRIPTION`))}
      </Helmet>
      <Grid container className={classes.container}>
        {/* HERO CTA SECTION */}
        <Grid item className={classes.heroLayout}>
          <Grid item align="center" className={classNames(classes.layout, classes.heroContent)}>
            <Typography
              variant="h1"
              align="center"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: t('UNA.PRO.TITLE') }}
            />
            <Typography
              variant="h4"
              align="center"
              className={classes.heroSecondaryTitle}
              paragraph
              dangerouslySetInnerHTML={{ __html: t('UNA.PRO.SUBTITLE') }}
            />
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              dangerouslySetInnerHTML={{ __html: t('UNA.PRO.SUBTITLE.BODY') }}
            />

            {/* <Button variant="outlined" color="inherit" component={Link} to="/leagues/1/candidate">
              {t("UNA.PRO.CANDIDATE")}
            </Button> */}

            <Button variant="outlined" color="inherit" href="mailto:hello@wello.fr">
              postuler
            </Button>
          </Grid>
        </Grid>

        {/* 8 BENEFIT SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.BENEFIT.TITLE') }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.BENEFIT.SUBTITLE') }}
              />
            </Grid>
          </Grid>

          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_1}
              title="UNA.PRO.BENEFIT.VALUE_1.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_2}
              title="UNA.PRO.BENEFIT.VALUE_2.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_3}
              title="UNA.PRO.BENEFIT.VALUE_3.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_3.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_4}
              title="UNA.PRO.BENEFIT.VALUE_4.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_4.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_5}
              title="UNA.PRO.BENEFIT.VALUE_5.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_5.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_6}
              title="UNA.PRO.BENEFIT.VALUE_6.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_6.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_7}
              title="UNA.PRO.BENEFIT.VALUE_7.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_7.CONTENT"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <ValueCard
              img={Images.una.value_8}
              title="UNA.PRO.BENEFIT.VALUE_8.TITLE"
              content="UNA.PRO.BENEFIT.VALUE_8.CONTENT"
            />
          </Grid>
        </Grid>

        {/* PRO PORTRAIT SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.DISCOVER.HEADLINE') }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.DISCOVER.SUBHEADING') }}
              />
            </Grid>
          </Grid>

          <Grid item md={4} xs={12}>
            <PortraitCard
              img={Images.una.avatar_pro_1}
              title="UNA.PRO.DISCOVER.PORTRAIT_1.TITLE"
              content="UNA.PRO.DISCOVER.PORTRAIT_1.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <PortraitCard
              img={Images.una.avatar_pro_2}
              title="UNA.PRO.DISCOVER.PORTRAIT_2.TITLE"
              content="UNA.PRO.DISCOVER.PORTRAIT_2.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <PortraitCard
              img={Images.una.avatar_pro_3}
              title="UNA.PRO.DISCOVER.PORTRAIT_3.TITLE"
              content="UNA.PRO.DISCOVER.PORTRAIT_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* PRO JOB SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.JOB.TITLE') }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: t('UNA.PRO.JOB.SUBTITLE') }}
              />
            </Grid>
          </Grid>
          <Grid spacing={2} container className={classes.layout}>
            <Grid item md={4} xs={12}>
              <div className={classes.proAsideBulletPoints} />
            </Grid>
            <Grid item md={8} xs={12}>
              <BulletPointContainer
                benefits={[
                  {
                    bullet: '01',
                    title: 'UNA.PRO.JOB.VALUE_1.TITLE',
                    content: 'UNA.PRO.JOB.VALUE_1.CONTENT',
                  },
                  {
                    bullet: '02',
                    title: 'UNA.PRO.JOB.VALUE_2.TITLE',
                    content: 'UNA.PRO.JOB.VALUE_2.CONTENT',
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
}

export default withStyles(styles)(Pro);
