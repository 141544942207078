import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SectionHero from '../../Components/PagesLeagues/SectionHero';
import SectionWho from '../../Components/PagesLeagues/SectionWho';
import Footer from '../../Components/Layout/Footer';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import { Images } from '../../Themes';
import { getCdnUrl } from '../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  mainLayout: {
    backgroundColor: '#ffffff',
    padding: 0,
    margin: 0,
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,
    margin: '6rem 2rem',
    [theme.breakpoints.only('xs')]: {
      padding: '1rem',
    },
  },
  img: {
    width: '100%',
    margin: theme.spacing(8, 0),
  },
}));

function Pro() {
  const classes = useStyles();
  return (
    <Grid className={classes.mainLayout}>
      {/* HERO SECTION */}
      <SectionHero
        title1="Donnez vie à leurs envies"
        title2="Rejoignez Reprise"
        subTitle="Réseau d’Entraide de Proximité à la Réalisation à l’Innovation et au Soutien des Envies"
        backgroundImage={`url(${Images.reprise.jumbo})`}
        title1Color1="#D43671"
        title1Color2="#D43671"
        title2Color1="#114294"
        title2Color2="#114294"
        subTitleColor="#114294"
        imageMD={5}
        fullWidthLayout
        paddingTop="4rem"
        layoutHeight="85vh"
      />

      <Divider style={{ margin: '3rem', background: 'none' }} />

      <SectionWho
        title="Devenez partenaire d’Empowercare"
        variantTitle="h3"
        bullet={[
          {
            bullet: ' ',
            content:
              '<p>Le projet propose, d’une part, de permettre à la personne âgée en perte d’autonomie de faire émerger des désirs et de retrouver un autre horizon que la réalisation des actes du quotidien en vue du maintien à domicile (qui sont pris en charge dans le cadre de l’APA), et d’autre part, de soulager l’aidant dans la prise en charge de son proche.</p><p>Prenez part au projet Empowercare afin d’apporter des solutions concrètes pour répondre aux envies des personnes en perte d’autonomie, leur permettre d’augmenter leur lien social et améliorer leur quotidien.</p>',
          },
        ]}
        image={getCdnUrl(`https://www.wello.fr/${Images.reprise.wishCard}`)}
      />

      <Divider style={{ margin: '2rem', background: 'none' }} />
      {/* FOOTER SECTION */}
      <Footer />
    </Grid>
  );
}

export default Pro;
